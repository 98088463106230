
import logo_proj from '../imgs/360 logo.svg'
export default function C360Logo() {
    return (
        <div className='c360_logo'>
            <div className='card_site'>
            <img
              src={logo_proj}
              className="customImgSize"
              alt="Genesys"
            />
            </div>
            <div className='site_name'>
                Adoption Hub
            </div>
        </div>
    )
}