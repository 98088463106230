import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from './SecureRoute.js';
import Adoption_breadth_aggregate from "../pages/adoption_breadth_aggregate.js"
import Account_View from "../pages/account_view.js"
import Adoption_breadth_customer from "../pages/adoption_breadth_customer.js"
import Loading from '../pages/Loading.js';
import { Context } from "../context/Contexts.js";
import Home from '../pages/Home.js';
const AppRoutes = () => {
    const { oktaActivated } =useContext(Context);

    return (
        <Routes>
            <Route path="/" element={oktaActivated?<RequiredAuth />:<Adoption_breadth_customer />} >
                <Route path='' element={<Adoption_breadth_customer />} />
            </Route>
            <Route path="/Adoption_breadth_customer" element={oktaActivated?<RequiredAuth />:<Adoption_breadth_customer />} >
                <Route path='' element={<Adoption_breadth_customer />} />
            </Route>
            <Route path="/Adoption_breadth_aggregate" element={oktaActivated?<RequiredAuth />:<Adoption_breadth_aggregate />} >
                <Route path='' element={<Adoption_breadth_aggregate />} />
            </Route>
            {/* <Route path="/Account_View" element={oktaActivated?<RequiredAuth />:<Account_View />} >
                <Route path='' element={<Account_View />} />
            </Route> */}
            <Route path='login/callback' element={<LoginCallback loadingElement={<Loading />} />} />
            <Route path='/Account_View_Okta' element={<RequiredAuth />}>
                <Route path='' element={<Account_View />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;





